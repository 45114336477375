export const profileLanguage = {
  ar: {
    school: "طالب المدرسة",
    college: "طالب جامعي",
    personalData: "البيانات الشخصية",
    educationalData: "البيانات التعليمية",
    passwordData: "إعادة تعيين كلمة المرور",
  },
  en: {
    school: "School Student",
    college: "College Student",
    personalData: "Personal Information",
    educationalData: "Educational Information",
    passwordData: "Password Reset",
  },
};

export const personalData = {
  ar: {
    student: "طالب",
    personalData: "البيانات الشخصية",
    Name: "الأسم",
    email: "البريد الالكتروني",
    button:"حفظ البيانات الشخصية المعدلة",
    cancel: "إلغاء التعديل",
  },
  en: {
    student: "Student",
    personalData: "Personal Data",
    Name: "Name",
    email: "Email",
    button: "Save Modified Personal Data",
    cancel: "Cancel Modification",
  },
};

export const passwordData = {
  ar: {
    password: "إعادة تعيين كلمة المرور",
    oldPassword: "كلمة السر القديمة",
    newPassword: "كلمة السر الجديدة",
    confirmPassword: "تأكيد كلمة السر",
    button: "حفظ كلمة السر المعدلة",
    cancel: "إلغاء التعديل",
  },
  en: {
    password: "Password Reset",
    oldPassword: "Old Password",
    newPassword: "New Password",
    confirmPassword: "Confirm Password",
    button: "Save Modified Password",
    cancel: "Cancel Modification",
  },
};

export const educationData = {
  ar: {
    educationalData: "البيانات التعليمية",
    save: "حفظ البيانات الشخصية المعدلة",
    college: "اختر الجامعة \ المدرسة",
    specialization:"اختر مسارك أو كليتك" ,
    cancel: "إلغاء التعديل",
  },
  en: {
    educationalData: "Educational data",
    save: "Save modified personal data",
    college: "Select university/school",
    specialization: "Choose your major/path ",
    cancel: "Cancel modification",
  },
};

export const subscription= {
  ar: {
    subscription: "تفاصيل الاشتراك",
    subscribed:"انت مشترك في:",
    plan:"باقة متميز ",
    option:"ريال سعودي",
    expire:"إلغاء",
    next:"تاريخ انتهاء الاشتراك: ",
    error:"لم تقم بالاشتراك"
  },
  en: {
    subscription: "Subscription Details",
    subscribed:"You are subscribed to:",
    plan:"Distinctive package",
    option:"SAR",
    expire:"Unsubscribe",
    next:"Next billing date:",
    error:"You have not Subscribed"
  },
}
