import { createSlice } from "@reduxjs/toolkit";
import { FlashCard } from "../../../Services/api/FlashCards.js";

const GetFlashCardsSlice = createSlice({
    name:"flashCards",
    initialState:{
        CardData:[],
        document_id:''
    },
    reducers:{
        GetData(state,action){
          state.CardData = [...state.CardData, ...action.payload];
        },
        addDocument: (state, action)=>{
            state.document_id = action.payload;
        },
        clearData:(state,action)=>{
          state.CardData = action.payload;
        }
    }
})

export const { GetData ,addDocument,clearData } = GetFlashCardsSlice.actions;
export const { reducer: FlashCardsReducer } = GetFlashCardsSlice;

const GetallCards = (FlashCardData) => async (dispatch) => {
  let retries = 0;
  const maxRetries = 30; // Max retries (90 seconds / 3 seconds)
  const delay = 3000; // 3 seconds delay between retries
  const controller = new AbortController(); // To cancel the request
  const signal = controller.signal;

  const fetchData = async () => {
    try {
      console.log("started");
      const response = await FlashCard.GetCards(FlashCardData, { signal });
      console.log("-----get all cards");
      console.log(response);
      console.log(response.status);

      if (response.status === 200) {
        localStorage.setItem('retry_api', 'false');
        localStorage.setItem("FlashCards", JSON.stringify(response.data.data));
        dispatch(GetData(response.data.data.flash_cards));
        return response;
      }
    } catch (error) {
      if (signal.aborted) {
        console.log("API request was aborted due to navigation change.");
        return; // Exit if the request was aborted
      }

      console.error("Error occurred:", error);
      localStorage.setItem('retry_api', 'true');

      // Retry on any error except for 200 and not aborted
      if (retries < maxRetries) {
        retries++;
        return new Promise((resolve) => {
          setTimeout(() => resolve(fetchData()), delay); // Retry after delay
        });
      } else {
        console.log("Max retries reached due to an error.");
        dispatch(GetData([]));
        return error;
      }
    }
  };

  // Function to stop the API call when the location changes
  const handleLocationChange = () => {
    console.log("Location changed, aborting API call.");
    controller.abort(); // Abort the API call
  };

  // Add an event listener to watch for location changes
  window.addEventListener('popstate', handleLocationChange);

  const result = fetchData(); // Start the process

  // Clean up the event listener when the operation is done
  return () => {
    window.removeEventListener('popstate', handleLocationChange);
  };
};



const CardByLimit = (Carddata) => async (dispatch) =>{
  try {
    const response = await FlashCard.GetByLimit(Carddata);
    if(response.status === 200){
      dispatch(GetData(response.data.data.flash_cards));
    }
  } catch (error) { 
    if(error?.response?.data?.error_code === 1022){
      dispatch(GetData([]));
    }
  }
}

const GetCardsPara = (FlashCardData) => async (dispatch) => {
    try {
      const response = await FlashCard.GetCardsParameter(FlashCardData);
      if (response) {
        dispatch(GetData(response.data.data.flash_cards));
        return response;
      }
    } catch (error) {
      console.log(error);
      if(error?.response?.data?.error_code === 1022){
        return error
      }
    }
};

const GetSearchData = (FlashCardData) => async (dispatch) => {
    try {
      const response = await FlashCard.GetSearchValue(FlashCardData);
      if (response) {
        dispatch(clearData([]));
        dispatch(GetData(response.data.data.flash_cards));
        return response;
      }
    } catch (error) {
      console.log(error)
      if(error?.response?.data?.error_code === 1022){
        dispatch(GetData([]));
        return error;
      }
    }
};

const GetSearchDataPara = (FlashCardData) => async (dispatch) => {
  try {
    const response = await FlashCard.GetSearchValuePara(FlashCardData);
    if (response) {
      dispatch(clearData([]));
      dispatch(GetData(response.data.data.flash_cards));
      return response;
    }
  } catch (error) {
    if(error?.response?.data?.error_code === 1022){
      dispatch(GetData([]));
      return error;
    }
  }
};

const RatingApi = (FlashRating) =>async (dispatch) =>{
  try {
    const response = await FlashCard.PostRating(FlashRating);

  } catch (error) {
    console.log(error);
  }
}

const Editcard = (editD) => async (dispatch)=>{
  try {
    const res = await FlashCard.UpdateCard(editD);
    return res;
  } catch (error) {
    console.log(error);
  }
}


const AddCardData = (AddD) => async (dispatch)=>{
  try {
    const res = await FlashCard.AddCard(AddD);
    return res;
  } catch (error) {
    console.log(error);
  }
}

const deleteCard = (deleteC) => async (dispatch) =>{
  try {
    const response = await FlashCard.DeleteFlashCard(deleteC);
    return response;
  } catch (error) {
    console.log(error);
  }
}

const UserUpdateResponse = (updatedRes) => async ()=>{
  try {
    const res = await FlashCard.UserResponse(updatedRes);
    return res;
  } catch (error) {
    console.log(error);
  }
}



export { GetallCards , GetCardsPara ,Editcard ,deleteCard , UserUpdateResponse,GetSearchData,RatingApi,AddCardData,CardByLimit,GetSearchDataPara};
export default GetFlashCardsSlice.reducer;