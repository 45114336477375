export const FlashCardsLearning = {
    ar:{
        UpperText:"ذاكر بتميز .. ",
        lowerText:"ركز ولا تتوتر ، نحن هنا معاك حتى تتمكن من إكمال ملفك بنجاح",
        StartText2:"أسحب البطاقة وإذا ما اتقنتها بنعيدها لك الى أن تتمكن منها",
        StartText1:"اختبر فهمك في كل معلومة",
        StartText:"بنختبرك في كل معلومة على شكل سؤال وجواب   ",
        StartButton:"ابدأ رحلة التعلم",
        BackButton:"ابدأ من جديد",
        Report:"إبلاغ",
        Explanation: "الشرح",
        Answer : "الإجابة",
        Skip:"تخطي",
        NeedReview:"تحتاج إلى مراجعة",
        Completed:"أتقنتها",
        Congrats:"ممتاز ، استمر !",
        CongratsBody:"",
        CompleteCount:"مكتمل",
        needsReviewCount:"بحاجة إلى مراجعة",
        RemainingCount:"متبقي",
        Rating:"تقييمك يساعدنا على التطوير والتحسين. شكرًا لك.",
        Repeat:"إعادة الاختبار",
        Review:"مراجعة مرة أخرى",
        Ending:"إنهاء",
        CongratsTest:"ممتاز ، استمر !",
        Correct:"مكتملة",
        Wrong:"غير متقنة",
        Exit:"الخروج",
        ExplanationLimitMessage:"لقد استنفدت حدود الشرح",
    },
    en:{
        UpperText:"Guidelines for evaluating user experience",
        lowerText:"This test will assess your current skills, helping us determine which subjects are right for you.",
        StartText:"Click we summarize the information for you in the form of question answer",
        StartText1:"Swipe and you can rate your understanding of each piece of information",
        StartText2:"Drag and if you master it, we will repeat it for you until you can do it",
        StartButton:"Completing the learning journey",
        BackButton:"Starting over",
        Report:"Report",
        Explanation: "Explan",
        Answer: "Answer",
        Skip:"Skip",
        NeedReview:"Need Review",
        Completed:"Completed",
        Congrats:"Congratulations! Your progress is amazing, keep it up",
        CongratsBody:"We hope this was a useful experience for you. Feel free to explore more of our educational courses.",
        CompleteCount:"Complete",
        needsReviewCount:"Need Review",
        RemainingCount:"Remaining",
        CongratsTest:"Congratulations! You have successfully completed the test",
        Rating:"Your rating helps us improve and develop the experience. Thank you",
        Repeat:"Exam Repetation",
        Review:"Review Again",
        Ending:"Ending",
        Correct:"Correct",
        Wrong:"Wrong",
        Exit:"Exit",
        ExplanationLimitMessage: "Explanation Limit Exhausted",
    }
}