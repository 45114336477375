export const FlashCardlang ={
    ar:{
        EditCard:"تعديل البطاقة ",
        AddCard: "إضافة بطاقة",
        All:"الجميع",
        Completed:"أتقنتها",
        NeedReview:"تحتاج إلى مراجعة",
        Stay:"متبقي لي",
        search:"ابحث",
        amendment:"تعديل",
        Delete:"احذف",
        remaining:"متبقي",
        Startlearning:"ابدأ",
        BottomLearn:"كيف حاب تكون طريقة مذاكرتك اليوم ؟",
        FlashCards:"بطاقات فلاش كارد",
        LearnPara:"ذاكر مادتك على هيئة اكمل الفراغ واختيار من متعدد و صح وخطأ",
        Test:"فلاش كارد",
        LearnTest:"الفلاش كارد تساعدك في حفظ المعلومات بسهولة مما يعزز رسخ المعلومات في الذاكرة",
        startTest:"ابدأ",
        TicketUpper:"ذاكر مع زميلك ",
        TicketLower:"شاركه تلخيصاتك حتى تسهل عليه المذاكرة",
        TheQues:"السؤال",
        TheAns:"الاجابة",
        save:"احفظ البطاقة",
        cancel:"الإلغاء",
        SummaryCard:"بطاقات ملخص الملف",
        Add:"إضافة بطاقة",
        AnimationBoxText: "جاري تحميل ملفك، ثواني ويجهز ملخصك" ,
        AnimationDescriptionText: "في انتظار خروج مستنداتك من الفرن الرقمي! انتظر جيدًا، إنه وقت الخبز.",
        dropDownDelete: "حمل الملف من هنا",
        copyNotification: "تم النسخ بنجاح",
    },
    en:{
        EditCard:"Edit Card",
        AddCard: "Add Card",
        All:"All",
        Completed:"Completed",
        NeedReview:"Review",
        Stay:"Stay",
        search:"search",
        amendment:"Amendment",
        Delete:"Delete",
        remaining:"Remaining",
        Startlearning:"Start learning",
        BottomLearn:"How would you like to go through your learning experience today",
        FlashCards:"Flash Cards",
        LearnPara:"Flash cards help save information efficiently and enhance the consolidation of information in long-term memory",
        Test:"Test",
        LearnTest:"Study the entire material in the form of multiple-choice questions based on your mastery of all concepts",
        startTest:"Start test ",
        TicketUpper:"No tickets for yourself",
        TicketLower:"Share your smart experience with your colleagues",
        TheQues:"The Question",
        TheAns:"The Answer",
        save:'Save the card',
        cancel:'cancelation',
        SummaryCard:'File summary cards',
        Add:'Add Card',
        AnimationBoxText: "Nothing to see here",
        AnimationDescriptionText: "Waiting for your docs to come out of the digital oven! Hang tight, it's baking time.",
        dropDownDelete: "Download file",
        copyNotification: "Copied Successfully",
    }
}